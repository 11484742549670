import axios from 'axios';
import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';;

export default function setup() {
    axios.interceptors.response.use((response) => {
        console.log('interceptors axios response=%o',response)
        if(response.data && response.data.status && response.data.status==='error' && response.data.message){
            console.log('interceptors axios response.data.message=%o',response.data.message)
            Loading.hide();
            Notify.create({
                color: 'red',
                textColor: 'white',
                type: 'negative',
                message: response.data.message,
                position: 'top',
                timeout: 3500,
            })
        }
        return response;
    }, (error) => {
        Loading.hide();
        const error_details = Object.values(error.response).toString();
        Notify.create({
            color: 'red',
            textColor: 'white',
            type: 'negative',
            message: error_details,
            position: 'top',
            timeout: 3500,
        })
        return Promise.reject(error);
    });
}
