

















import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ContacteleMeleColorMetal extends Vue {

  public goToLoginPage(){
    this.$router.push({name: 'Login'});
  }
}
