import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import user from '@/store/user';
import {URL_API} from '@/config';
import {getModule} from 'vuex-module-decorators';
import {TUser} from '@/types/TUser';

@Component({components: {}})
export default class MenuApp extends Vue {
    @Prop({ default: '0' }) public readonly userid!: string;
    @Prop() public onCloseMenu!: ()=>void;
    public nrOfMyDocuments = 0;
    public nrOfPendingToRegister = 0;
    public nrDocumenteDeRepartizate = 0;
    public $refs: any;
    public userStore = getModule(user);
    public goToView(pRouteViewName: string ) {
        const vueInst=this;
        this.$router.push({ name: pRouteViewName }).catch(()=>{vueInst.onCloseMenu();});
    }

    public getNrOfDocumentsForMe():void{
        this.nrDocumenteDeRepartizate = 3;
    }


    public get user(): TUser {
        return this.userStore.user;
    }

    public onGoToBrowseCategories(){
        this.$router.push({name: 'BrowseCategories',  params: { pid: '0' }});
        //this.$router.push({name: 'BrowseCategories' });
    }

    public openTehnipedia(){
        window.open("https://color-metal.ro/ro/tehnipedia");
    }

    public created(): void {
        const vueInst = this;
    }


}
