import { Component, Vue } from 'vue-property-decorator';
import { getModule} from 'vuex-module-decorators';
import axios, {AxiosPromise} from 'axios';
import {CONFIG_ENV} from '@/config';
import user from '@/store/user';
import basket from '@/store/basket';
import favorites from '@/store/favorites';
import nomenclatoare from '@/store/nomenclatoare';
import {TUser} from '@/types/TUser';
import MenuApp from './components/MenuApp/MenuApp.vue';
import ContacteleMeleColorMetal from "@/components/ContacteleMeleColorMetal.vue";
import {ServiceUser} from '@/services/ServiceUser';
import {getNomenclatoare} from '@/modules/getNomenclatoare'
import {getFavorites} from '@/modules/getFavorites'
import {getBasket} from '@/modules/getBasket'
import { ionLogoFacebook } from '@quasar/extras/ionicons-v5'
import { ionLogoYoutube } from '@quasar/extras/ionicons-v5'
import { ionLogoLinkedin } from '@quasar/extras/ionicons-v5'
/*
var MockAdapter = require('axios-mock-adapter');
var mock = new MockAdapter(axios);
mock.onGet('/loadApp').reply(200, {
    userData:{ username:'Florin Codreanu',
        id:'florin.codreanu@bcr.ro',
        empno:12789}
});
*/

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// axios.defaults.headers.common['X-CSRF-TOKEN'] = '1t6bh0lwh72pv.2k9aurnh5tk44';

@Component({components: {MenuApp,ContacteleMeleColorMetal}})
export default class App extends Vue {
    public leftDrawerOpen = false;
    public rightDrawerOpen = false;
    public visibleMobileBarForSearch =false;
    public loadingResultForFastSearch = false;
    public currentPageTitle= '';
    public inputFastSearch = '';
    public nrRanduriTabel = 10;
    public ionLogoFacebook = ionLogoFacebook;
    public ionLogoYoutube = ionLogoYoutube;
    public ionLogoLinkedin = ionLogoLinkedin;
    public visibleInputFastSearch = false;
    public $refs: any;
    public userStore = getModule(user);
    public storeNomenclatoare = getModule(nomenclatoare);
    public storeBasket = getModule(basket);
    public storeFavorites = getModule(favorites);
    public div_el: HTMLDivElement | undefined;
    public iframe: any;
    public mesajSeLoadingNomenclatoare='Se incarca nomenclatoarele... Va rugam, asteptati!...';
    constructor() {
        super();
    }

    get nrArticlesInBasket():number{
       return this.storeBasket.basket.length;
    }

    get nrArticlesInFavorites():number{
        return this.storeFavorites.favorites.length;
    }

    get showAddBtnBarMenu():boolean{
        let result = false;
        if(this.$route.name=='AdminArticles' || this.$route.name=='AdminCategory' || this.$route.name=='AdminUsers' || this.$route.name=='AdreseLivrare'){
            result=true;
        }
        return result;
    }

    get showSearchBtnBarMenu():boolean{
        let result = false;
        if(this.$route.name=='MyShoppingCart'){
            result=true;
        }
        return result;
    }

    get showBasketBtnBarMenu():boolean{
        return this.$route.name!='MyShoppingCart' && this.userStore.PREVIOUS1_ROUTE_NAME !='MyShoppingCart' && this.$route.name !='AdreseLivrare' && this.$route.name !='AdminArticles' && this.$route.name !='AdminCategory' && this.$route.name !='AdminUsers';
    }

    get user(): TUser {
        return this.userStore.user;
    }

    get isLoadingNomenclatoare(): boolean {
        return this.storeNomenclatoare.isLoadingNomenclatoare;
    }

    get visibleMobileRightBtnForDashboard():boolean{
        return this.userStore.PREVIOUS1_ROUTE_NAME != 'Dashboard' && this.userStore.PREVIOUS2_ROUTE_NAME != 'Dashboard'
            && (this.$route.name=='MyShoppingCart' || this.$route.name=='BrowseArticles');
    }

    get sessionIsValid(): boolean {
        return (this.userStore.userIsAuthenticated && !this.storeNomenclatoare.isLoadingNomenclatoare?true:false);
    }

    public goToDashboardInMobile(){
        this.$router.push({name: 'Dashboard'});
        this.userStore.set_showmenubar(false);
        this.userStore.set_showbackbar(false);
    }

    public setNrRanduriTabel(){
        localStorage.setItem('nrRanduriTabel', this.nrRanduriTabel.toString());
    }

    public clearInputFastSearch(){
        this.inputFastSearch='';
        this.$refs.refOptionsFastSearch.hide();
    }

    public clearListFastSearch(){
        if(!this.inputFastSearch){
            console.log('clearListFastSearch')
        }
    }

    public onGoToBrowseCategories(){
        this.onHideInputFastSearch();
        this.$router.push({name: 'BrowseCategories',  params: { pid: '0' }});
        //this.$router.push({name: 'BrowseCategories' });
    }

    public goToFavorite(){
        this.onHideInputFastSearch();
        this.$router.push({name: 'ArticoleFavorite'});
    }

    public onShowInputFastSearch(){
        this.visibleInputFastSearch=true;
    }

    public onHideInputFastSearch(){
        this.inputFastSearch='';
        this.visibleInputFastSearch=false;
        this.visibleMobileBarForSearch=false;
    }

    public get currentRouteName() {
        return this.$route.name;
    }
    get userIsAuthenticated(){
        return this.userStore.userIsAuthenticated;
    }

    get showMenuBar(){
        return this.userStore.showMenuBar;
    }

    get showBackBar(){
        return this.userStore.showBackBar;
    }

    get titleBackBar(){
        return this.userStore.titleBackBar;
    }

    get MyImgProfileString64():string{
        return this.userStore.MyImgProfileString64;
    }

    get pageTransition():string{
        return this.userStore.pageTransition;
    }

    public goToMyProfile(){
        this.$router.push({ name: 'MyProfile' });
    }

    public goBack(){
        this.$router.back();
    }

    public getOptionSArticlesFastSearch(pInputSearch: string){
        const vueInst = this;
        if(pInputSearch && pInputSearch.length>1){
            vueInst.$refs.refOptionsFastSearch.show();
        }else{
            vueInst.$refs.refOptionsFastSearch.hide();
        }

        vueInst.loadingResultForFastSearch=false;
    }

    public logout(){
        localStorage.setItem('isAuthenticatedUser', 'n');
        localStorage.setItem('userToken', 'x');
        localStorage.setItem('userid', 'x');
        this.userStore.set_user_is_authenticated(false);
        this.$q.notify({
            color: 'purple',
            textColor: 'white',
            position:'top',
            timeout: 1000,
            message: 'Ati fost delogat de la aplicatie!'
        });
        this.$router.push({ name: 'home' });
        this.userStore.set_user({
            appid: '',
            userid: '',
            firstName: '',
            lastName: '',
            emailAddress: '',
            phoneNr:'',
            isAdmin: 'n',
            functie: '',
            userToken: '',
            companyName:'',
            companyCode:''});
        this.$router.push({name: 'About'});
    }

    public onCloseMenu(){
        this.leftDrawerOpen=false;
    }

    public checkToken(){
        const vueInst = this;
        const userToken=localStorage.getItem('userToken')||'x';
        const userId=localStorage.getItem('userId')||'x';
        ServiceUser.getCurrentUser(userId,userToken).then(response=>{
            if(response.status=='success'){
                getFavorites();
                getBasket();
                // this.$q.fullscreen.request().then(()=>{
                //     vueInst.$q.notify({
                //         color: 'teal',
                //         textColor: 'white',
                //         icon: 'positive',
                //         position: 'top',
                //         timeout: 2000,
                //         message: 'FullScreen cu success!'
                //     })
                // }) .catch(err => { console.log('err on Fullscreen=%o',err)
                //
                //     vueInst.$q.notify({
                //         color: 'red',
                //         textColor: 'white',
                //         icon: 'positive',
                //         position: 'top',
                //         timeout: 2000,
                //         message: 'Eroare la FullScrren!'
                //     })
                // });
                localStorage.setItem('isAuthenticatedUser', 'y');
                vueInst.userStore.set_user(response.user);
                vueInst.userStore.set_user_is_authenticated(true);
                vueInst.storeNomenclatoare.set_loading_nomenclatoare(true);
                ServiceUser.getProfileImgAsString(vueInst.userStore.user.appid).then((response:any)=>{
                    localStorage.setItem('MyImgProfileString64', response.data);
                    vueInst.userStore.set_myimgprofilestring64(response.data);
                });
                vueInst.storeNomenclatoare.getNomenclatoare().then(response=>{
                    if(response=='success'){
                        vueInst.storeNomenclatoare.set_loading_nomenclatoare(false);
                        vueInst.$router.push({name: 'Dashboard'});
                        vueInst.userStore.set_showmenubar(true);
                        vueInst.userStore.set_showbackbar(false);
                    }
                });
            }else{
                localStorage.setItem('isAuthenticatedUser', 'n');
                localStorage.setItem('userToken', 'x');
                localStorage.setItem('userId', 'x');
                vueInst.userStore.set_user_is_authenticated(false);
                vueInst.$router.push({name: 'Login'});
            }
        });
    }

    public makeMobileBarForSearch(){
        console.log('makeMobileBarForSearch')
        this.visibleMobileBarForSearch=true;
    }

    public onClickAddBtnForDialogChild(){
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.$eventHub.$emit('eventClickAddBtn', {emitter:'app'})
    }

    public getAppUser() {
        const vueInst = this;
        vueInst.$q.fullscreen.request();
        const isAuthenticatedUser=localStorage.getItem('isAuthenticatedUser')||false;
        if(isAuthenticatedUser){
            vueInst.checkToken();
        }else{
            vueInst.$router.push({name: 'Login'});
        }

    }

    public putFocusBackToFastSearch(){
        this.$refs.refInputFastSearch.focus();
    }

    public get widthForDrawerDocument(): number{
        let result=0;
        if(this.$q.platform.is.mobile) {result = window.innerWidth;}
        else{
            if(screen.availWidth>1500){ result = 1200;}
            else{
                if(screen.availWidth>1000){ result = 1000;}
                else {
                    if (screen.availWidth > 800) {
                        result = 600;
                    } else {
                        result = window.innerWidth - 100;
                    }
                }
            }
        }
        return result;
    }

    public onGoTOMyShoppingCart(){
        this.$router.push({name: 'MyShoppingCart'});
    }

    public closeWindowDocument(){
        this.rightDrawerOpen=false;
    }

    public created(): void {
        const vueInst = this;
        vueInst.userStore.set_screenwidth(screen.availWidth);
        vueInst.userStore.set_screenheight(screen.availHeight);
        getNomenclatoare();
        vueInst.getAppUser();
    }

}
