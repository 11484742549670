import Vue from 'vue'

import './styles/quasar.scss'
import 'quasar/dist/quasar.ie.polyfills'
import lang from 'quasar/lang/ro.js'
import '@quasar/extras/material-icons/material-icons.css'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import Loading from 'quasar/src/plugins/Loading.js';import Ripple from 'quasar/src/directives/Ripple.js';import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';

Vue.use(Quasar, {
    directives: {
        Ripple
    },
    extras: [
        'material-icons',
        'ionicons-v4',
    ],
  config: {
      brand: {
          primary: '#f7c325',
          secondary: '#2c88d9',
          accent: '#9C27B0',

          dark: '#1d1d1d',

          positive: '#21BA45',
          negative: '#C10015',
          info: '#e8833a',
          warning: '#F2C037'
      },
      loading:{
            spinnerColor: 'white',
            spinnerSize: 140,
            backgroundColor: 'blue-grey-10',
            message: 'Se incarca date... Asteptati...',
            messageColor: 'white'
          }
  },
  plugins: {
      Notify, Loading, Dialog, AppFullscreen
  },
  lang: lang
 })
