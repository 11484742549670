// tslint:disable-next-line:only-arrow-functions
export const URL_API = ( function() {
    if ( location.hostname === 'localhost') {
        return 'http://localhost:83'
        //return 'https://portal-color.theappsonline.com/api'
        //return 'http://regisdra-test/api';
    } else {
        //return 'http://localhost/api'
        //return 'https://regis.theappsonline.com/api'
        return '/api';
    }
})();

export const URL_API_FILE = ( function() {
    if ( location.hostname === 'localhost') {
        return 'http://localhost:83'
        //return 'https://regis.theappsonline.com/api'
        //return 'http://regisdra-test/api';
    } else {
        //return 'http://localhost/api'
        //return 'https://regis.theappsonline.com/api'
        //return '/api';
        return 'http://regisdra-files/api';
    }
})();

// tslint:disable-next-line:only-arrow-functions

export const CONFIG_ENV = {
    URL_USER: {
        getCurrentUser : URL_API + '/user/get_current_user',
        loginUser : URL_API + '/user/login',
        saveMyProfile : URL_API+ "/user/save_my_profile",
        getMyImageProfileAsString: URL_API + '/user/my_image_profile_as_string',
        getMyImageProfile : URL_API+ "/user/get_my_image_profile",
        uploadImageMyProfile : URL_API+ "/user/upload_image_my_profile"
    },
    URL_ADMIN: {
        users : URL_API + '/admin/users',
        userDetails: URL_API + '/admin/users/user_details',
        resetPassword : URL_API + '/admin/users/reset_password',
        saveNewUser : URL_API + '/admin/users/new_user',
        getCompaniesByFilter: URL_API + '/admin/companies/filter',
        nomCategory: URL_API + '/admin/category',
        nomProduct: URL_API + '/admin/product',
        imageCategoryAsString: URL_API + '/admin/category/image_as_string',
        treeDataCategories: URL_API + '/admin/category/treedata',
        listDataCategories: URL_API + '/admin/category/listdata'
    },

    URL_USER_ADRESE_LIVRARE: {
        adresa : URL_API + '/user/adrese_livrare',
        adrese : URL_API + '/user/adrese_livrare'
    },

    URL_COMPANY_USERS: {
        allUsers : URL_API + '/user/company_users',
    },

    URL_ARTICOL:{
       articol: URL_API + '/articol',
       browseArticles: URL_API + '/browse/articles',
       identifyArticleInDB: URL_API + '/browse/identify_article',
       identifyArticleInDBAndPutIntoFavorites : URL_API + '/favorites/identify_article_and_put_in_favorites',
       identifyArticleInDBAndPutIntoBasket : URL_API + '/basket/identify_article_and_put_in_basket',
       favorites: URL_API + '/favorites',
       basket: URL_API + '/basket'
    },

    URL_CATEGORY:{
        browseCategories: URL_API + '/browse/categories',
        getJPG: URL_API + '/browse/category/image',
    },

    URL_OFFER:{
        sendBasketForAnOffer: URL_API + '/offer/send_basket_for_an_offer',
        getMyOffers: URL_API + '/offer/my_offers',
        OneOffer: URL_API + '/offer/offer',
    },

    URL_CERERE:{
        cerere: URL_API + '/cerere',
    },

    myLocale: {
        /* starting with Sunday */
        days: 'Duminica_Luni_Marti_Miercuri_Joi_Vineri_Sambata'.split('_'),
        daysShort: 'Dum_Lun_Mar_Mie_Joi_Vie_Sam'.split('_'),
        months: 'Ianuarie_Februarie_Martie_Aprilie_Mai_Iunie_Iulie_August_Septembrie_Octombrie_Noiembrie_Decembrie'.split('_'),
        monthsShort: 'Ian_Feb_Mar_Apr_Mai_Iun_Iul_Aug_Sep_Oct_Nov_Dec'.split('_'),
        firstDayOfWeek: 1
    }
}


export const ArticolNull={
    appid:'',
    pid:'',
    categoryPid:'',
    lantHierarchyCategories: '',
    code: '',
    name:'',
    UMBase:'',
    isActive:false,
    withLength: false,
    withWidth: false,
    withThickness: false,
    withDiameter: false,
    sizeLength:null,
    sizeWidth:null,
    sizeThickness:null,
    sizeDiameter:null
}
