import Vue from 'vue'
import Component from 'vue-class-component'
import App from './App.vue'
import VueRouter from 'vue-router';
import router from './router';
import {store} from './store'
import interceptors from './modules/interceptors';
import VuePageTransition from 'vue-page-transition';
import numeral from 'numeral';
import './quasar'
import './assets/site.scss';
interceptors();
Vue.config.productionTip = false;
Vue.use(VuePageTransition);
Vue.use(VueRouter);
Vue.filter('fmt_currency0', function (money:string|number|null) {return numeral(Math.round(Number(money) * 100) / 100).format('0,0');})
Vue.filter('fmt_currency2', function (money:string|number|null) {return numeral(Math.round(Number(money) * 100) / 100).format('0,0.00');})
Vue.filter('fmt_currency3', function (money:string|number|null) {return numeral(Math.round(Number(money) * 1000) / 1000).format('0,0.000');})
Vue.filter('fmt_currency4', function (money:string|number|null) {return numeral(Math.round(Number(money) * 10000) / 10000).format('0,0.0000');})

Vue.prototype.$eventHub = new Vue(); // Global event bus
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate' // for vue-router 2.2+
]);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
