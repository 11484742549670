import { Component, Vue } from 'vue-property-decorator';
import { getModule} from 'vuex-module-decorators';
import axios, {AxiosPromise} from 'axios';
import {CONFIG_ENV} from '@/config';
import user from '@/store/user';
import basket from '@/store/basket';


export function getBasket() {
    const storeBasket = getModule(basket);
    if (typeof (Worker) !== "undefined") {
        // Yes! Web worker support!
        const workerBasket = new Worker("/workers/basket.js");
        workerBasket.postMessage({cmd: 'getBasket'});
        workerBasket.onmessage = function (event) {
            switch (event.data.cmd) {
                case 'resultBasket':
                    storeBasket.set_products_to_basket(event.data.products)
                    break;
            }
        };
    }
}
